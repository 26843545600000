export default [
  {
    title: 'Highly Recommend',
    content:
      'I highly recommend this for anyone who has a fur baby with joint, muscle, skin issue etc. I am not one to right a review however this product has been a life send. \n\n Our pup is 9 years young and he hurt his knee. So we bought this and it has done wonders for him. We found out he tore his ACL and has to have surgery. But this has definitely helped him get better than he was when he 1st hurt it. We will continue to use this up till he has surgery after. We also will be using on his hips and spine for arthritis. We actually bought a second one so we can do 2 areas at once',
    customerName: 'Amazon Customer',
    customerTitle: '',
    customerImage: 'https://placeimg.com/150/150/people',
  },
  {
    title: 'It’s easy to operate & quick',
    content:
      'We have just started using it on our dog but early results seem quite good. She does not mind the treatment at all. It’s quick & easy to do. Everything is as advertised.',
    customerName: 'Amazon Customer',
    customerTitle: '',
    customerImage: 'https://placeimg.com/150/150/people',
  },
  {
    title: 'Good value for the money',
    content:
      'The Tendlite Vetlite is a great portable size, and seems really effective on small areas. The dogs enjoy it!',
    customerName: 'Michelle Rae',
    customerTitle: '',
    customerImage: '',
  },
];
