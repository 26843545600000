import React from 'react';
import Button from '../components/Button';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import customerData from '../data/customer-data';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import { StaticImage } from 'gatsby-plugin-image';

const Index = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Vetlight® by Tendlite®</title>
      <link rel="canonical" href="https://vetlight.com" />
      <meta
        name="description"
        content="VETLIGHT Soothing Red Light Relief for Dog, Cat & Horses Joint and Muscle Reliever Professional Grade device for Animal Home Care"
      />
    </Helmet>
    <section className="pt-20 md:pt-40 lg:pb-20" id="top">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-4xl xl:text-6xl font-bold leading-none">
            Vetlight® by Tendlite®
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Introducing the newest addition to the Tendlite® Brand. The VETLIGHT is our Animal
            version of the Tendlite to provide the best noninvasive pain relief for your pet.
          </p>
          <p className="mt-8 md:mt-12">
            <Link
              to="https://www.amazon.com/VETLIGHT-Pain-Relief-Therapy-Horses/dp/B072FHQ7D7/"
              target="_blank"
              className="buy-button py-4 px-12 bg-primary hover:bg-primary-darker rounded text-white"
            >
              Buy Now
            </Link>
          </p>
        </div>
        <div className="lg:w-1/2  mt-20 lg:mt-0">
          <StaticImage
            src="../images/vetlight.png"
            alt="Vetlight"
            width="800"
            placeholder="tracedSVG"
            layout="fullWidth"
          />
        </div>
      </div>
    </section>

    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <p className="mt-8 text-2xl font-light feature-text">
        VETLIGHT Soothing Red Light Relief for Dog, Cat & Horses Joint and Muscle Reliever
        Professional Grade device for Animal Home Care{' '}
      </p>
    </section>

    <SplitSection
      id="features"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            New Advanced Relief For Your Pet!
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Includes Free Manual showing how to effectively use Red Light Therapy on your animals.
            This product is for animals only, Not for human use. This product is not intended to
            diagnose, treat, cure, or prevent any disease or health condition.
          </p>
        </div>
      }
      secondarySlot={
        <StaticImage
          src="../images/1.jpg"
          alt="Vetlight"
          width="800"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      }
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Choose Natural Red Light Relief</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            VETLIGHT Red Light Therapy device is designed for provide soothing relief. It is drug
            free. Red light is a noninvasive way to soothe your pet's muscles and joints in the
            comfort of your home!
          </p>
        </div>
      }
      secondarySlot={
        <StaticImage
          src="../images/2.jpg"
          alt="Vetlight"
          width="800"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      }
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Developed on the solid foundation and safety of the TENDLITE.
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            VetLight is Easy to Use with efficient protocols that aim at fast delivery of
            therapeutic red light to you furry friends.
          </p>
        </div>
      }
      secondarySlot={
        <StaticImage
          src="../images/3.jpg"
          alt="Vetlight"
          width="800"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      }
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Power | Performance</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Powerful, Veterinary Quality Therapeutic Stainless Steel Red LED Device for Animal
            wellness at home. Portable and cordless. Great for traveling with your pet.
          </p>
        </div>
      }
      secondarySlot={
        <StaticImage
          src="../images/5.jpg"
          alt="Vetlight"
          width="800"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      }
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight"></h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Utilizes the same Red wavelength commonly used in Veterinary Offices to quickly and
            safely bring soothing relief to Pets.
          </p>
        </div>
      }
      secondarySlot={
        <StaticImage
          src="../images/4.jpg"
          alt="Vetlight"
          width="800"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      }
    />

    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <p>
        <a
          href="https://www.amazon.com/VETLIGHT-Pain-Relief-Therapy-Horses/dp/B072FHQ7D7/"
          target="_blank"
          className="buy-button py-4 px-12 bg-primary hover:bg-primary-darker rounded text-white"
        >
          Buy Now
        </a>
      </p>
    </section>

    <section id="reviews" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map((customer) => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
  </Layout>
);

export default Index;
